<!-- @format -->

<template>
  <div>
    <div class="prokino-footer" :class="$mq">
      <a
        :class="$mq"
        :href="$config.kinoservice.finder + $config.kinoservice.slug"
        target="_blank"
        >KINOFINDER</a
      >
      <a
        :class="$mq"
        :href="$config.kinoservice.alert + $config.kinoservice.id"
        target="_blank"
        >FILMWECKER</a
      >
      <a
        :class="$mq"
        href="https://www.prokino.de/impressum.html"
        target="_blank"
        >IMPRESSUM</a
      >
      <a
        :class="$mq"
        href="https://www.prokino.de/movies/datenschutzbestimmungen"
        target="_blank"
        >DATENSCHUTZ</a
      >
      <a :class="$mq" href="https://prokino.medianetworx.de/" target="_blank"
        >PRESSESERVER</a
      >
    </div>
    <div v-if="billing" class="prokino-billing">
      <img :class="$mq" src="@/assets/images/billing.png" alt />
    </div>
    <div class="prokino-cline">
      &copy; 2020 Prokino. Alle Rechte vorbehalten. made by
      <a href="http://www.novagraphix.de" target="_blank">novagraphix</a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
<style lang="scss">
  .prokino-footer {
    $self: &;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1rem;
    justify-content: center;
    a {
      color: white;
      &:hover {
        color: $tertiary;
      }
      &:not(:last-child):after {
        color: white;
        content: '|';
        padding: 0 5px 0 5px;
      }
      &.sm {
        &:after {
          content: '';
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
    &.md,
    &.lg,
    &.xl,
    &.xxl {
      flex-direction: row;
    }
  }
  .prokino-billing {
    width: 100%;
    &.lg,
    &.xl,
    &.xxl {
      margin-bottom: 1.5rem;
      padding: 0 5rem;
    }
    img {
      margin: 0 auto;
      padding: 0 5rem;
      &.sm,
      &.md {
        width: 100%;
      }
      &.lg,
      &.xl,
      &.xxl {
        width: auto;
      }
    }
  }
  .prokino-cline {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    color: white;
    padding: 0 1rem;
    a {
      color: white;
      &:hover {
        color: $primary;
      }
    }
  }
</style>
